@import '../../../assets/variables.scss';

section.about {
  padding-top: .3rem;
  padding-bottom: .3rem;
  @include min-full-height(-2.05rem);

  .row {
    display: flex;
  }

  .column {
    flex: 50%;
  }

  @include m {
    padding-top: .16rem;
    padding-bottom: .16rem;
    background-color: white;
    @include min-full-height(-1.72rem);
  }

  .banner {
    position: relative;
  }


  .banner-text {
    background-color: $red;
    position: absolute;
    bottom: 0.2rem;
    left: 0.3rem;
    padding-top: 0.14rem;
    padding-bottom: 0.14rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    font-weight: 700;
    font-size: .2rem;
    color: white;
    border-radius: 6rem 0px 6rem 0px;
  }

  .about-container {
    background-color: white;

    @include d {
      padding: .32rem .4rem .16rem .4rem;
      border-radius: .06rem;
    }

    .banner-img {
      max-width: 100%;
      height: auto;
    }


    p {
      padding-bottom: 0.15rem;
    }

    @include m {
      .title {
        font-weight: 700;
        font-size: .2rem;
      }
    }
  }

}