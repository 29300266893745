@import '/src/assets/variables.scss';

.form {
  .sms-countdown + .btn {
    margin-top: .12rem;
  }

  .sms-desc {
    margin-top: .16rem;
    font-weight: 400;
    font-size: 0.14rem;
    line-height: 1.2em;
    color: #757575;

    b {
      color: $darkgray;
    }
  }

  .sms-countdown {
    display: flex;
    justify-content: space-between;
    margin-top: .48rem !important;

    @include m {
      margin-bottom: -.2rem;
      font-weight: 400;
      font-size: .11rem;
    }
  }
}