@import '../../../assets/variables.scss';

section.onboarding-form {
  padding-top: 0;
  padding-bottom: 1.64rem;
  @include min-full-height(-.8rem);

  @include m {
    padding-bottom: .92rem;
    @include min-full-height(-.6rem);
  }

  &.no-pb {
    padding-bottom: 0;

    @include m {
      padding-bottom: 0;
    }
  }

  > .container {
    position: static;
  }

  .steps {
    padding-top: .26rem;
    padding-bottom: .2rem;
    counter-reset: steps;
    border-bottom: 1px solid #DFE1EA;

    @include m {
      padding-top: .16rem;
      padding-bottom: .16rem;
      border-bottom: none;
    }

    .container {
      display: flex;
      justify-content: space-between;

      .step {
        position: relative;
        display: flex;
        justify-content: center;
        width: 2rem;
        padding-top: .16rem;
        padding-bottom: .16rem;
        border: 1px solid #EFEFEF;
        border-radius: .06rem;
        background-color: #FFFFFF;
        box-shadow: 0 .02rem .02rem rgba(0, 0, 0, 0.04);

        @include m {
          flex-wrap: wrap;
          width: calc(25% - .07rem);
          padding-top: .06rem;
          padding-bottom: .06rem;
          font-size: .12rem;
          text-align: center;
        }

        &:before {
          counter-increment: steps;
          content: counter(steps);
          width: .21rem;
          height: .21rem;
          margin-right: .08rem;
          font-weight: 700;
          font-size: 0.14rem;
          line-height: .21rem;
          color: $darkblue;
          text-align: center;
          border-radius: .21rem;
          background-color: #DFE1EA;

          @include m {
            display: block;
            width: .19rem;
            height: .19rem;
            margin-right: 0;
            margin-bottom: .02rem;
            line-height: .19rem;
          }
        }

        &.active {
          color: white;
          border-color: $darkblue;
          background-color: $darkblue;

          &:before {
            background-color: white;
          }

          &.error {
            color: $darkblue;
            background-color: white;
            border-color: $red;

            &:before {
              color: white;
              background-color: $darkblue;
            }

            &:after {
              content: '';
              position: absolute;
              width: .2rem;
              height: .2rem;
              top: -.08rem;
              left: -.08rem;
              border-radius: .2rem;
              background-color: white;
              @include bg-image("../assets/Icons/step-error.svg", .16rem);
            }
          }
        }

        &.valid {
          &:after {
            content: '';
            position: absolute;
            width: .2rem;
            height: .2rem;
            top: -.08rem;
            left: -.08rem;
            border-radius: .2rem;
            background-color: white;
            @include bg-image("../assets/Icons/step-valid.svg", .16rem);
          }
        }
      }
    }
  }

  .form {
    padding-top: .24rem;

    @include m {
      padding-top: 0;
    }

    > .title {
      @include m {
        font-weight: 700;
        font-size: .18rem;
      }
    }

    form {
      display: flex;
      flex-wrap: wrap;
      margin: -.16rem -.1rem;

      @include m {
        margin: -.12rem -.06rem;
      }

      .form-item {
        width: calc(33.333% - .2rem);
        margin: .16rem .1rem;

        @include m {
          width: calc(100% - .12rem);
          margin: .12rem .06rem;
        }

        &.full {
          width: calc(100% - .2rem);

          @include m {
            width: calc(100% - .12rem);
          }
        }

        &.radio {
          width: 4.4rem;

          @include m {
            width: calc(100% - .12rem);
          }
        }

        &.mobile-half {
          @include m {
            width: calc(50% - .12rem);
          }
        }
      }

      .title {
        width: calc(100% - .2rem);
        margin: .16rem .1rem .08rem .1rem;

        @include m {
          margin: .08rem .06rem 0 .06rem;
          font-weight: 600;
        }
      }
    }

    * + .text {
      margin-top: .16rem;
    }

    * + form {
      margin-top: .24rem;

      @include m {
        margin-top: .04rem;
      }
    }

    .actions {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      padding-top: .24rem;
      padding-bottom: .24rem;
      background-color: #F0F1F3;

      @include m {
        padding-top: .1rem;
        padding-bottom: .1rem;
      }

      .container {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .result {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include min-full-height(-.8rem);

    .content {
      width: 5.3rem;
      padding: .32rem .54rem;
      text-align: center;
      background: #FFFFFF;
      border-radius: .06rem;
      border: 1px solid #F3F3F3;

      * + * {
        &.title {
          margin-top: .32rem;
        }

        &.text {
          margin-top: .24rem;
        }
      }
    }
  }

  .tabs {
    @include d {
      border: 1px solid #EFEFEF;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
      border-radius: .06rem;
    }

    @include m {
      margin-left: -.16rem;
      margin-right: -.16rem;
    }

    .tab-menu {
      display: flex;
      justify-content: stretch;
      align-items: stretch;
      border-bottom: 1px solid #EFEFEF;

      @include m {
        border-top: 1px solid #EFEFEF;
        border-color: #d8d8d8;
      }

      a {
        display: flex;
        justify-items: center;
        width: 33.333%;
        text-align: center;

        & + a {
          border-left: 1px solid #EFEFEF;

          @include m {
            border-color: #d8d8d8;
          }
        }

        span {
          width: 100%;
          padding: .16rem;

          @include m {
            background-color: #EFEFEF;
          }

          &.title {
            @include d {
              background-color: #EFEFEF;
            }

            @include m {
              font-weight: 600;
              font-size: .12rem;
              background-color: white;
            }
          }
        }
      }
    }

    .tab-items {
      padding: .2rem;

      @include m {
        padding: .18rem .16rem;
      }

      .title {
        font-weight: 600;
        font-size: .16rem;
        color: $darkgray;
      }

      .text {
        font-weight: 400;
        font-size: .14rem;
      }
    }
  }

  * + .tabs {
    margin-top: .24rem;

    @include m {
      margin-top: .16rem;
    }
  }
}