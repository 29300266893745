@import '../../../assets/variables.scss';

section.main-boxes {
  text-align: center;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2rem;
    left: 0;
    bottom: 0;
    background-color: #F4F6FB;
  }

  .container > * + * {
    margin-top: .1rem;
  }

  * + .boxes {
    margin-top: .32rem;

    @include m {
      margin-top: .2rem;
    }
  }

  .boxes {
    position: relative;
    display: flex;
    z-index: 2;

    @include d {
      margin-left: -.08rem;
      margin-right: -.08rem;
    }

    @include m {
      flex-direction: column;
    }

    &:before {
      content: '';
      position: absolute;
      width: 2.46rem;
      height: 1.34rem;
      right: -.84rem;
      bottom: -.28rem;
      background-image: url(../../../assets/Icons/dots.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      @include m {
        right: -1.38rem;
        bottom: -.24rem;
      }
    }

    .box {
      position: relative;
      width: 33.333%;
      padding: .32rem .12rem;
      background: #FFFFFF;
      border: 1px solid #E8E8E8;
      border-radius: .2rem;
      z-index: 3;

      @include d {
        margin: .08rem;
      }

      @include m {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 2.72rem;
        padding: .24rem;

        & + .box {
          margin-top: .16rem;
        }
      }

      img {
        width: .52rem;

        @include m {
          width: .38rem;
        }
      }

      .title.s2 {
        font-size: .26rem;

        @include m {
          font-size: .2rem;
        }
      }

      > * + * {
        margin-top: .16rem;

        @include m {
          margin-top: .12rem;
        }
      }
    }
  }
}