@import '../auth.scss';

section.register {
  .success {
    position: relative;
    padding: .32rem .42rem;
    text-align: center;
    border-radius: .06rem;
    background-color: white;

    > * + * {
      margin-top: .32rem;

      &.text {
        margin-top: .16rem;
      }
    }

    .btn {
      width: 100%;
    }
  }
}