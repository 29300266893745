@import '../../../assets/variables.scss';

header {
  position: relative;
  padding-top: .14rem;
  padding-bottom: .14rem;
  box-shadow: 0 .02rem .08rem rgba(0, 0, 0, 0.08);

  @include m {
    padding-top: .15rem;
    padding-bottom: .15rem;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    width: rem;

    @include m {
      width: .66rem;
    }
  }

  .btn-logout {
    display: inline-flex;
    align-items: center;
    border: none;
    background: transparent;
    cursor: pointer;
    margin-left: .6rem;

    img {
      width: .24rem;
    }

    span {
      font-weight: 600;
      font-size: 0.14rem;
      line-height: 1.2em;
      color: #b4b4b4;
    }

    * + * {
      margin-left: 8px;
    }
  }

  .btn-menu {
    border: none;
    background-color: transparent;
    box-shadow: none;

    img {
      width: .24rem;
    }
  }

  .menu-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include m {
      display: none;
      position: absolute;
      width: 100%;
      left: 0;
      top: calc(100% + .15rem);
      padding: .1rem .16rem;
      background-color: white;
      z-index: 100;
      box-shadow: 0px 2px .08rem inset rgba(0, 0, 0, 0.08);
      @include full-height(-$headerMobileHeight);

      &.active {
        display: block;
      }
    }

    .menu {
      a {
        @include m {
          display: block;
          padding: .22rem .16rem;
          font-weight: 500;
          font-size: .14rem;
        }

        & + a {
          @include d {
            margin-left: .6rem;
          }
        }

        &.active {
          color: #D51317;
        }
      }
    }

    .actions {
      position: relative;

      @include d {
        width: 3.15rem;
        margin-left: .5rem;
      }

      @include m {
        margin-top: .04rem;
        padding-top: .24rem;
        border-top: 2px solid #F3F3F3;
      }

      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: .24rem;
        left: -10px;
        top: 50%;
        margin-top: -.12rem;
        background-color: #D2D2D2;

        @include m {
          display: none;
        }
      }

      .btn {
        width: 1.5rem;

        &:last-child {
          width: 1.65rem;

          @include m {
            width: 100%;
          }
        }

        @include m {
          width: 100%;

          &.tertiary {
            border: 1px solid #D51317;
          }

          & + .btn {
            margin-top: .24rem;
            width: 100% !important;
          }
        }
      }
    }
  }
}