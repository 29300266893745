@import '../../../assets/variables.scss';

section.content {
  padding-top: .36rem;
  padding-bottom: .36rem;

  @include m {
    padding-top: .16rem;
    padding-bottom: .16rem;
  }

  .container {
    display: flex;
  }

  .image {
    position: relative;

    img {
      border-radius: .2rem;
    }
  }

  .box {
    padding-right: .4rem;

    @include m {
      padding-right: 0;
    }

    > * + * {
      margin-top: .16rem;

      &.btn {
        margin-top: .36rem;

        @include m {
          margin-top: .32rem;
        }
      }
    }

    > * + ul {
      margin-top: .32rem;

      @include m {
        margin-top: .16rem;
      }
    }

    > .title {
      @include m {
        font-weight: 700;
        font-size: .2rem;
      }
    }

    .btn {
      min-width: 2.6rem;

      @include m {
        width: 100%;
      }
    }

    ul {
      list-style: none;
      counter-reset: contentlist;

      li {
        position: relative;
        padding-left: .36rem;

        @include m {
          padding-left: .3rem;
        }

        &:before {
          counter-increment: contentlist;
          content: counter(contentlist);
          position: absolute;
          left: 0;
          top: .04rem;
          width: .24rem;
          height: .24rem;
          font-weight: 700;
          font-size: 0.14rem;
          line-height: .24rem;
          color: white;
          text-align: center;
          border-radius: .24rem;
          background-color: $darkblue;

          @include m {
            top: .03rem;
            width: .2rem;
            height: .2rem;
          }
        }

        & + li {
          margin-top: .24rem;

          @include m {
            margin-top: .16rem;
          }
        }

        > * + * {
          margin-top: .12rem;

          @include m {
            margin-top: .06rem;
          }
        }

        .title {
          @include m {
            font-size: .16rem;
          }
        }
      }
    }
  }

}