.cookie-container {
    position: fixed;
    z-index: 99;
    bottom: 0;
    flex-direction: column;
    background-color: #ffe6ee;
    border-radius: 15px;
    margin-bottom: 15px;
    margin-right: 15%;
    margin-left: 15%;
}

.cookie-title {
    color: #FA0050;
    font-size: 15px;
    font-weight: bold;
    margin-left: 15px;
    margin-top: 15px;
}

.cookie-text {
    color: #FA0050;
    font-size: 15px;
    margin: 15px 15px 15px 15px;
}

.cookie-buttons {
    align-items: center;
    flex-direction: row;
    float: right;
    margin: 10px 60px 10px 10px;
    display: flex;
}


.cookie-button {
    background-color: #FA0050;
    border-color: #D2D2D2;
    border-width: 0;
    font-size: 15px;
    color: #D2D2D2;
    margin-right: 10px;
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
}

.cookie-checkboxs {
    align-items: center;
    flex-direction: row;
    float: left;
    margin-bottom: 15px;
    font-size: 15px;
    background-color: #D2D2D2;
}

.cookie-checkbox {
    font-size: 15px;
    width: 5%;
    height: 5%;
    border: #18C175;
    color: #18C175;
}

.cookie-label {
    font-size: 15px;
}