@import '../../../../assets/variables.scss';

section.dashboard .payout {
  .btn-back {
    display: inline-flex;
    align-items: center;

    img {
      width: .24rem;
    }

    span {
      margin-left: .1rem;
    }
  }

  > * + .title {
    margin-top: .14rem;

    @include m {
      font-weight: 700;
      font-size: .2rem;
    }
  }

  .box-container {
    padding: .32rem;
    border-radius: .08rem;
    background-color: white;

    @include m {
      padding: 0;
      border-radius: 0;
      background-color: transparent;
    }

    .box {
      width: 4.8rem;
      padding: .32rem .18rem;
      border: 1px solid $athensgray;
      border-radius: .06rem;
      box-shadow: 0 0 .16rem rgba(0, 0, 0, 0.04);
      background-color: white;

      @include m {
        width: 100%;
        padding: .16rem;
        border-radius: .1rem;
      }

      .info {
        display: flex;
        justify-content: space-between;
        padding-bottom: .24rem;
        border-bottom: 1px solid $lightgray;

        @include m {
          flex-wrap: wrap;
          padding-bottom: .16rem;
        }

        .title {
          @include m {
            width: 100%;
            font-weight: 700;
            font-size: .18rem;
          }
        }

        .price {
          color: $darkgray;

          @include m {
            font-size: .2rem;
          }
        }

        > * + * {
          @include m {
            margin-top: .05rem;
          }
        }
      }

      .form {

        > .title {
          @include m {
            font-weight: 700;
            font-size: .18rem;
          }
        }

        form {
          .btn {
            width: 100%;

            @include m {
              height: .42rem;
            }
          }

          * + .form-btn-container {
            margin-top: .32rem;
          }

          .currency:before {
            content: 'TL';
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: .48rem;
            height: calc(100% - 2px);
            top: 1px;
            right: 1px;
            font-family: "Poppins", sans-serif;
            font-weight: 700;
            font-size: 0.14rem;
            line-height: 1.2em;
            color: $graychat;
            background: $concretegray;
            border-radius: 0 .05rem .05rem 0;
          }
        }

        * + form {
          margin-top: .16rem;
        }
      }

      * + .form {
        margin-top: .48rem;

        @include m {
          margin-top: .16rem;
        }
      }
    }
  }

  * + .box-container {
    margin-top: .24rem;

    @include m {
      margin-top: .16rem;
    }
  }

  .popup-wrapper {

    @include m {
      padding: 0;
    }

    .popup-container {
      width: 5.6rem;
      padding: .24rem .38rem;
      text-align: left;

      @include m {
        width: 100%;
        padding: .16rem .2rem;
        border-radius: 0;
      }

      .payout-table {
        .table-header {
          padding: .16rem .2rem;
          font-weight: 700;
          font-size: 0.18rem;
          line-height: 1.2em;
          color: white;
          background-color: $downriverdark;
          border-radius: .08rem .08rem 0 0;

          @include m {
            padding: .14rem .12rem;
            font-size: .14rem;
          }
        }

        table {
          width: 100%;
          border-collapse: collapse;
          background-color: white;
          box-shadow: 0 .02rem .16rem rgba(0, 0, 0, 0.04);
          border-radius: 0 0 .08rem .08rem;

          th {
            padding: .14rem .1rem;
            color: $downriver;
            text-align: right;
            background-color: $downriverlight;

            @include m {
              padding: .06rem;
              font-size: .12rem;
            }

            &:first-child {
              padding-left: .2rem;
              text-align: left;

              @include m {
                padding-left: .12rem;
              }
            }

            &:last-child {
              padding-right: .2rem;

              @include m {
                padding-right: .12rem;
              }
            }
          }

          td {
            padding: .16rem .1rem;
            text-align: right;
            border-top: 1px solid $lightgray;

            @include m {
              padding: .07rem;
              font-weight: 500;
              font-size: .14rem;
            }

            &:first-child {
              padding-left: .2rem;
              text-align: left;

              @include m {
                padding-left: .12rem;
              }
            }

            &:last-child {
              padding-right: .2rem;

              @include m {
                padding-right: .12rem;
              }
            }
          }
        }

        > .text {
          color: #757575;
          margin-top: .16rem;
        }
      }

      * + .payout-table {
        margin-top: .16rem;
      }

      .payout-description {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .title {
          width: 100%;
          color: $darkgray;

          @include m {
            font-weight: 600;
            font-size: .14rem;
          }
        }

        .desc-item {
          width: 33.333%;

          @include m {
            width: 100%;
          }

          .text {
            color: #757575;

            @include m {
              display: inline;
              font-weight: 400;
              color: $darkgray;

              & + .text {
                margin-left: .04rem;
              }
            }
          }
        }

        > * + * {
          margin-top: .12rem;

          @include m {
            margin-top: .05rem;
          }
        }
      }

      * + .payout-description {
        margin-top: .12rem;
      }

      .btn {
        width: calc(50% - .08rem);
        margin-top: 0;

        @include m {
          width: 100%;
          order: 2;
        }

        &.cancel {
          color: $graychat;
          background-color: $concretegray;
        }

        & + .btn {
          @include d {
            margin-left: .16rem;
          }

          @include m {
            order: 1;
            margin-bottom: .16rem;
          }
        }
      }

      .buttons {
        @include m {
          display: flex;
          flex-wrap: wrap;
          padding: .2rem .16rem;
          margin-left: -.2rem;
          margin-right: -.2rem;
          margin-bottom: -.16rem;
          box-shadow: 0 -.02rem .08rem rgba(0, 0, 0, 0.08);
        }
      }

      * + .buttons {
        margin-top: .2rem;

        @include m {
          margin-top: .32rem;
        }
      }
    }
  }
}