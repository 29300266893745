@import '/src/assets/variables.scss';

section.auth {
  padding-top: .3rem;
  padding-bottom: .3rem;
  @include min-full-height(-2.05rem);

  @include m {
    padding-top: .16rem;
    padding-bottom: .48rem;
    background-color: white;
    @include min-full-height(-1.72rem);
  }

  .form {
    @include d {
      padding: .32rem .4rem .16rem .4rem;
      border-radius: .06rem;
      background-color: white;
    }

    @include m {
      padding-top: .12rem;
    }

    .title {
      @include m {
        font-weight: 700;
        font-size: .2rem;
      }
    }

    * + .text {
      margin-top: .16rem;
    }

    * + form {
      margin-top: .24rem;

      @include m {
        margin-top: .16rem;
      }
    }

    .form-item + .form-item {
      margin-top: .24rem;
    }

    * + .form-btn-container {
      margin-top: .3rem;
    }

    .btn {
      width: 100%;
    }

    .btn + .btn {
      margin-top: .12rem;

      @include m {
        margin-top: .2rem;
      }
    }
  }

  .bottom-text {
    margin-top: .16rem;
    text-align: center;

    @include m {
      margin-top: .32rem;
    }
  }

  .nav {
    display: flex;
    padding: .04rem;
    background-color: #F3F3F3;
    border-radius: .06rem;

    a {
      width: 50%;

      &:not(.active) {
        color: #616161;
        background-color: transparent;
      }
    }
  }
}