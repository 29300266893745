@import '../../../assets/variables.scss';

section.dashboard {
  display: flex;
  padding-top: 0;
  padding-bottom: 0;

  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    height: .8rem;

    @include m {
      height: .6rem;
    }
  }

  .menu-wrapper {
    width: 2.58rem;
    background: #FFFFFF;
    box-shadow: .08rem 0 .16rem rgba(0, 0, 0, 0.04);
    @include min-full-height();

    @include m {
      display: none;
      position: fixed;
      width: 100%;
      max-height: calc(100% - .6rem);
      top: .6rem;
      left: 0;
      z-index: 100;
      overflow: auto;
    }

    .top {
      justify-content: flex-start;
      padding-left: .27rem;
      padding-right: .27rem;
      border-bottom: 1px solid $athensgray;

      .logo {
        width: .88rem;
      }
    }

    .menu {
      padding: .08rem .12rem;

      @include m {
        padding: .16rem .19rem;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: .48rem;
        padding-left: .12rem;
        padding-right: .12rem;

        @include m {
          height: .52rem;
          padding-left: .15rem;
          padding-right: .15rem;
        }

        &.active {
          border-radius: .06rem;
          box-shadow: 0 0 .08rem rgba(213, 19, 23, 0.32);
          background-color: #D51317;

          path {
            fill: white !important;
          }

          span {
            color: white;
          }
        }

        > * + span {
          margin-left: .08rem;
        }

        + a {
          margin-top: .16rem;

          @include m {
            margin-top: .18rem;
          }
        }
      }
    }
  }

  &.menu-active .menu-wrapper {
    @include m {
      display: block;
    }
  }

  .content-wrapper {
    width: calc(100% - 2.58rem);

    @include m {
      width: 100%;
    }

    .top {
      justify-content: flex-end;
      padding-left: .36rem;
      padding-right: .36rem;
      background-color: white;
      box-shadow: 0 .08rem .16rem rgba(0, 0, 0, 0.04);

      @include m {
        justify-content: space-between;
        padding-left: .16rem;
        padding-right: .16rem;
      }

      .logo {
        width: .66rem;
      }

      .btn-menu {
        border: none;
        background-color: transparent;
        box-shadow: none;

        img {
          width: .24rem;
        }
      }

      .account-top {
        position: relative;
        width: 2.06rem;
        border-radius: .06rem;
        border: 1px solid $lightgray;
        background-color: white;
        box-shadow: .08rem 0 .16rem rgba(0, 0, 0, 0.04);

        &.active {
          border-color: rgba(125, 131, 167, 0.8);

          .icon-dropdown {
            transform: rotate(180deg);
          }
        }

        .account-button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: .52rem;
          cursor: pointer;

          img {
            width: .24rem;
          }

          * + img {
            margin-left: .08rem;
          }

          * + span {
            margin-left: .06rem;
          }
        }

        .account-menu {
          position: absolute;
          width: 100%;
          left: 0;
          top: 100%;
          margin-top: .07rem;
          padding-top: .1rem;
          background-image: url(../../../assets/Icons/user-menu-bg.png);
          background-size: 100% 100%;
          background-position: center;
          background-repeat: no-repeat;
          z-index: 10;

          a {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: .6rem;
            padding-left: .24rem;
            padding-right: .24rem;

            &:not(:hover) span {
              color: $graychat;
            }

            * + span {
              margin-left: .08rem;
            }
          }
        }
      }
    }

    .content-container {
      position: relative;
      padding: .32rem .4rem;
      overflow: auto;
      @include full-height(-.8rem);

      @include m {
        padding: .16rem .18rem;
        @include full-height(-.6rem);
      }

      > .title {
        @include m {
          font-weight: 700;
          font-size: .2rem;
        }
      }

      .card-container {
        display: flex;
        flex-wrap: wrap;
      }

      * + .dashboard-form {
        margin-top: .24rem;

        @include m {
          margin-top: .16rem;
        }
      }

      .dashboard-form {
        position: relative;
        display: flex;

        @include m {
          flex-wrap: wrap;
          margin-left: -.18rem;
          margin-right: -.18rem;
          background-color: white;
          overflow: hidden;
        }

        .form {
          width: calc(50% - .26rem);
          max-width: 100%;
          padding: .32rem;
          background: white;
          border-radius: .06rem;
          box-shadow: 0 .02rem .16rem rgba(0, 0, 0, 0.04);

          @include m {
            width: 100%;
            padding: .24rem .16rem;
            box-shadow: none;
            border-radius: 0;
          }

          & + .form {
            @include d {
              margin-left: .52rem;
            }

            @include m {
              border-top: 1px solid $concretegray;
            }
          }

          * + form {
            margin-top: .24rem;

            @include m {
              margin-top: .16rem;
            }
          }

          form + .text {
            margin-top: .12rem;
          }

          .title {
            margin-bottom: .16rem;
          }

          .form-item + .form-item {
            margin-top: .24rem;
          }

          .btn {
            width: 100%;
            margin-top: .24rem;
          }

          .spacer {
            width: calc(100% + .64rem);
            height: 2px;
            margin: .2rem -.32rem;
            background-color: $concretegray;

            @include m {
              width: calc(100% + .32rem);
              margin: .16rem -.16rem;
            }
          }
        }
      }
    }
  }
}