@import '../../../assets/variables.scss';

section.main-content {
  overflow: hidden;

  .container {
    display: flex;

    @include m {
      flex-direction: column-reverse;
    }
  }

  .image {
    position: relative;
    width: 4.4rem;

    @include m {
      width: 100%;
      margin-top: .72rem;
    }

    img {
      position: relative;
      width: 100%;
      z-index: 2;
    }

    &:before {
      content: '';
      position: absolute;
      width: 2.46rem;
      height: 1.34rem;
      left: -.84rem;
      top: -.5rem;
      background-image: url(../../../assets/Icons/dots.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      @include m {
        left: -1.38rem;
        top: -.45rem;
      }
    }

    &.dot-bottom:before {
      top: auto;
      bottom: -.56rem;

      @include m {
        bottom: -.45rem;
      }
    }

    & + .content {
      width: calc(100% - 4.4rem);
      text-align: left;

      @include m {
        width: 100%;
      }

      .btn {
        width: calc(50% - .12rem);

        @include m {
          width: 100%;
        }
      }
    }
  }

  .content {
    width: 100%;
    text-align: center;

    @include d {
      padding-left: 1.1rem;
    }

    > * + * {
      margin-top: .16rem;
    }

    > .pre-title + * {
      margin-top: .04rem;

      @include m {
        margin-top: .1rem;
      }
    }

    > * + .btn {
      margin-top: .36rem;

      @include m {
        margin-top: .24rem;
      }
    }

    .text.s1 {
      line-height: 2em;
    }

    ul {
      list-style: none;
      counter-reset: maincontentlist;

      li {
        position: relative;
        padding-left: .44rem;
        line-height: 1.5em;

        @include m {
          place-content: .46rem;
        }

        &:before {
          counter-increment: maincontentlist;
          content: counter(maincontentlist);
          position: absolute;
          left: 0;
          top: 50%;
          width: .32rem;
          height: .32rem;
          margin-top: -.16rem;
          font-weight: 600;
          font-size: 0.18rem;
          line-height: .32rem;
          color: #7789b7;
          text-align: center;
          border-radius: .32rem;
          background-color: #F4F6FB;

          @include m {
            width: .3rem;
            height: .3rem;
            line-height: .3rem;
            border-radius: .3rem;
          }
        }

        & + li {
          margin-top: 1.5em;
        }
      }
    }

    .btn {
      width: 2.3rem;

      @include m {
        width: 100%;
      }

      & + .btn {
        @include d {
          margin-left: .24rem;
        }

        @include m {
          margin-top: .24rem;
        }
      }
    }
  }
}

@include d {
  header + section.main-content {
    padding-top: 1rem;
  }
}