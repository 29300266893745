@import 'variables.scss';

/* General Bootstrap */

html {
  font-size: 100px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  -webkit-font-smoothing: antialiased;

  @include m {
    font-size: 26.66666vw;
  }

  &.lock {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

body {
  position: relative;
  min-height: 100vh;
  font-size: 0;
  background-color: #F8F9FB;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-appearance: none !important;
  -webkit-font-smoothing: antialiased;
}

*:focus {
  outline: none !important;
}

a {
  color: inherit;
  text-decoration: none;
}

b, strong {
  font-weight: 700;
}

.font * {
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
}

.font img {
  display: block !important;
  width: auto !important;
  height: auto !important;
  max-width: 100% !important;
}


/* General */

section {
  position: relative;
  padding-top: .68rem;
  padding-bottom: .68rem;

  @include m {
    padding-top: .24rem;
    padding-bottom: .24rem;
  }
}

.container {
  position: relative;
  width: 11.46rem;
  max-width: 100%;
  padding-left: .3rem;
  padding-right: .3rem;
  margin-left: auto;
  margin-right: auto;

  &.small {
    width: 5.9rem;
  }

  @include m {
    padding-left: .16rem;
    padding-right: .16rem;
  }
}


/* Typography */

.title.s1 {
  font-weight: 700;
  font-size: 0.35rem;
  line-height: 1.5em;
  color: $darkblue;

  @include m {
    font-size: .26rem;
  }
}

.title.s2 {
  font-weight: 700;
  font-size: 0.3rem;
  line-height: 1.5em;
  color: $darkblue;

  @include m {
    font-size: .2rem;
  }
}

.title.s3 {
  font-weight: 600;
  font-size: 0.2rem;
  line-height: 1.5em;
  color: $darkblue;

  @include m {
    font-weight: 700;
    font-size: .18rem;
  }
}

.title.s4 {
  font-weight: 700;
  font-size: 0.24rem;
  line-height: 1.5em;
  color: $darkblue;

  @include m {
    font-weight: 600;
    font-size: .16rem;
  }
}

.title.s5 {
  font-weight: 600;
  font-size: 0.16rem;
  line-height: 1.5em;
  color: $darkblue;

  @include m {
    font-weight: 500;
  }
}

.title.s6 {
  font-weight: 700;
  font-size: 0.14rem;
  line-height: 1.5em;
  color: $darkblue;
}

.title.s7 {
  font-weight: 700;
  font-size: 0.2rem;
  line-height: 1.5em;
  color: $darkblue;

  @include m {
    font-weight: 600;
    font-size: .14rem;
  }
}

.text.s1 {
  font-weight: 500;
  font-size: 0.16rem;
  line-height: 1.5em;
  color: $darkgray;

  @include m {
    font-size: .14rem;
  }
}

.text.s2 {
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 1.5em;
  color: $darkgray;
}

.text.s3 {
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 1.5em;
  color: $darkgray;

  @include m {
    font-weight: 600;
  }
}

.text.s4 {
  font-weight: 400;
  font-size: 0.11rem;
  line-height: 1.5em;
  color: $darkgray;

  @include m {
    font-size: .12rem;
  }
}

.text.s5 {
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 1.5em;
  color: $darkgray;

  @include m {
    font-weight: 400;
    font-size: .11rem;
  }
}

.button.s1 {
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 1.5em;
  color: $darkblue;
}

.button.s2 {
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 1.5em;
  color: $darkblue;

  @include m {
    font-weight: 700;
    font-size: .12rem;
  }
}

.button.s3 {
  font-weight: 600;
  font-size: 0.12rem;
  line-height: 1.5em;
  color: $darkblue;

  @include m {
    font-weight: 500;
  }
}

.pre-title {
  position: relative;
  display: inline-block;
  padding-bottom: .05rem;
  font-weight: 600;
  font-size: 0.2rem;
  line-height: 1.5em;
  color: $downriver;

  @include m {
    font-size: .16rem;
  }

  &:before {
    content: '';
    position: absolute;
    width: .87rem;
    height: 2px;
    left: 50%;
    bottom: 0;
    margin-left: -.44rem;
    background-color: $downriver;

    @include m {
      width: .6rem;
      margin-left: -.3rem;
    }
  }
}

.title *, .text *, .button * {
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
}


/* Buttons */

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: .42rem;
  padding-left: .3rem;
  padding-right: .3rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 1.2em;
  color: white;
  background-color: $green;
  border-radius: .06rem;
  border: none;
  cursor: pointer;

  @include m {
    padding-left: .24rem;
    padding-right: .24rem;
  }

  &.large {
    height: .52rem;
  }

  &.small {
    height: .36rem;

    @include m {
      height: .32rem;
    }
  }

  &.secondary {
    background-color: $red;
  }

  &.tertiary {
    color: $red;
    border: 1px solid $red;
    background-color: white;

    &.blue {
      color: $darkblue;
      border-color: $darkblue;
    }

    &.no-border {
      border: none;
    }

    &.no-bg {
      background-color: transparent;
    }
  }

  &.back {
    &:before {
      content: '';
      width: .24rem;
      height: .24rem;
      margin-right: .02rem;
      @include bg-image("../../src/assets/Icons/arrow-left.svg", contain);
    }
  }

  &[disabled],
  &.disabled {
    color: $graychat !important;
    border: none;
    background-color: $concretegray;
    cursor: default;
  }

}

/* Card */

.card {
  width: calc(51% - .12rem);
  max-width: 100%;
  padding: .32rem .24rem;
  background: white;
  border-radius: .06rem;
  box-shadow: 0 .02rem .16rem rgba(0, 0, 0, 0.04);

  @include m {
    width: 100%;
    padding: .16rem;
    border-radius: .1rem;
  }

  * + * {
    margin-top: .24rem;

    @include m {
      margin-top: .12rem;
    }
  }

  &.small,
  &.large {
    * + * {
      margin-top: .2rem;

      @include m {
        margin-top: .12rem;
      }

      &.text {
        margin-top: .12rem;
      }
    }
  }

  &.small {
    width: calc(32% - .12rem);

    @include m {
      width: 100%;
    }
  }

  &.large {
    width: calc(68% - .12rem);

    @include m {
      width: 100%;
    }
  }

  &.full {
    width: 100%;
  }

  + .card {
    @include d {
      margin-left: .24rem;
    }

    @include m {
      margin-top: .16rem;
    }
  }

  .btn {
    @include m {
      width: 100%;
      height: .42rem;
    }
  }
}


/* Table */

.table {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: calc(51% - .12rem);

  &.full {
    width: 100%;
  }

  * + & {
    margin-top: .4rem;

    @include m {
      margin-top: .16rem;
    }
  }

  > .title {
    width: 50%;
  }

  &:not(.mobile-full) {
    @include m {
      width: 100%;
      background-color: #FFFFFF;
      box-shadow: 0 .02rem .16rem rgba(0, 0, 0, 0.04);
      border-radius: .08rem;
    }

    &.link-padding {
      @include m {
        padding-bottom: .5rem;
      }
    }

    > .title {
      @include m {
        width: 100%;
        padding: .14rem .12rem;
        font-weight: 700;
        font-size: .14rem;
        color: white !important;
        background-color: $downriverdark;
        border-radius: .08rem .08rem 0 0;
      }
    }
  }

  &.mobile-full {
    @include m {
      width: calc(100% + .36rem);
      margin-left: -.18rem;
      margin-right: -.18rem;
    }

    > .title {
      @include m {
        width: 100%;
        font-weight: 700;
        font-size: .2rem;
        padding-left: .18rem;
        padding-right: .18rem;
      }
    }

    form {
      @include m {
        padding-left: .18rem;
        padding-right: .18rem;
      }
    }

    * + table {
      @include m {
        margin-top: .16rem;
      }
    }
  }

  .detail-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;

    @include m {
      position: absolute;
      justify-content: center;
      width: 100%;
      height: .5rem;
      left: 0;
      bottom: 0;
    }

    .button {
      color: $graychat;

      @include m {
        color: $darkblue;
      }
    }
  }

  form {
    width: 100%;

    .form-item {
      width: 3rem;

      @include m {
        width: 100%;
      }
    }
  }

  * + form {
    margin-top: .16rem;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 .08rem .16rem rgba(0, 0, 0, 0.04);

    @include m {
      border-radius: .06rem;
      background-color: white;
    }

    td {
      @include d {
        padding-left: .2rem;
        padding-right: .2rem;
      }

      &:last-child:not(:first-child) {
        @include d {
          text-align: right;
        }
      }
    }

    thead {
      @include m {
        display: none;
      }

      td {
        padding-top: .14rem;
        padding-bottom: .14rem;
        color: white !important;
        background-color: $downriverdark;

        &:first-child {
          border-top-left-radius: .06rem;
        }

        &:last-child {
          border-top-right-radius: .06rem;
        }
      }
    }

    tbody {
      tr {
        @include m {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: .12rem;
          border-bottom: 1px solid $lightgray;
        }

        td {
          @include d {
            padding-top: .16rem;
            padding-bottom: .18rem;
            background-color: white;

            &:first-child {
              border-bottom-left-radius: .06rem;
            }

            &:last-child {
              border-bottom-right-radius: .06rem;
            }
          }

          @include m {
            width: 50%;
            font-weight: 500 !important;
            font-size: .14rem !important;

            &:nth-child(1) {
              order: 3;
              margin-top: .08rem;
              color: $graychat;
            }

            &:nth-child(2) {
              order: 1;
            }

            &:nth-child(3) {
              order: 4;
              margin-top: .08rem;
              text-align: right;
            }

            &:nth-child(4) {
              order: 2;
              text-align: right;
            }
          }
        }

        &:not(:last-child) td {
          @include d {
            border-bottom: 1px solid $lightgray;
          }
        }
      }
    }
  }

  * + table {
    margin-top: .24rem;

    @include m {
      margin-top: 0;
    }
  }

  .error {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2.1rem;
    padding-left: .5rem;
    padding-right: .5rem;
    background-color: #FFFFFF;
    box-shadow: 0 .02rem .16rem rgba(0, 0, 0, 0.04);
    border-radius: 0 0 .08rem .08rem;

    @include m {
      height: 1.36rem;
      background-color: transparent;
      box-shadow: none;
      border-radius: 0;
      text-align: center;
    }

    .text {
      color: $graychat;
    }
  }
}


.loading {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, .5);

  .loader {
    width: 80px;
    height: 80px;

    &:after {
      content: "";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid #fff;
      border-color: #fff transparent #fff transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@include m {
  .desktop-only {
    display: none !important;
  }
}

@include d {
  .mobile-only {
    display: none !important;
  }
}