@import '../../../assets/variables.scss';

section.dashboard .popup-wrapper {
  &.phone {
    .popup-container {
      padding: .32rem .42rem;
      text-align: left;

      .btn {
        width: 100%;
      }

      * + .text {
        margin-top: .16rem;
      }

      * + form {
        margin-top: .24rem;
      }

      * + .form-btn-container {
        margin-top: .32rem;
      }

      * + .buttons {
        margin-top: .1rem;
      }
    }
  }

  &.confirm {
    .popup-container {
      padding: .24rem .36rem;
      text-align: left;

      .btn {
        width: 100%;

        &.back:before {
          display: none;
        }
      }

      * + .btn {
        margin-top: .1rem;
      }
    }
  }
}