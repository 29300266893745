@import '../../../assets/variables.scss';

.popup-wrapper {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: .5rem;
  background-color: rgba(0, 0, 0, .64);
  z-index: 100;
  overflow: auto;

  @include m {
    padding: .36rem;
  }

  .popup-container {
    position: relative;
    width: 5.3rem;
    max-width: 100%;
    padding: .4rem .16rem;
    text-align: center;
    background: #FFFFFF;
    border: 1px solid #EBEEF2;
    box-shadow: 0px .08rem .16rem rgba(0, 0, 0, 0.04);
    border-radius: .08rem;

    @include m {
      width: 100%;
      padding: .24rem .16rem;
    }

    .image {
      max-width: 2rem;
      max-height: 1rem;

      @include m {
        max-width: 1.2rem;
        max-height: 1.2rem;
      }
    }

    .btn {
      width: 2rem;

      @include m {
        width: 100%;
      }
    }

    .btn-close {
      position: absolute;
      top: -.38rem;
      right: -2px;
      border: none;
      background-color: transparent;
      box-shadow: none;

      img {
        width: .24rem;
      }
    }

    .title {
      @include m {
        font-weight: 700;
        font-size: .18rem;
      }
    }

    * + .btn {
      margin-top: .32rem;

      @include m {
        margin-top: .24rem;
      }
    }
  }

  &.result {
    .popup-container {
      @include d {
        padding-top: .55rem;
      }
    }

    .text {
      @include d {
        padding-left: .48rem;
        padding-right: .48rem;
      }
    }

    * + .title {
      margin-top: .32rem;

      @include m {
        margin-top: .24rem;
      }
    }

    * + .text {
      margin-top: .18rem;

      @include m {
        margin-top: .16rem;
      }
    }
  }

  &.full {
    @include m {
      top: $headerMobileHeight;
      background-color: white;
      @include full-height(-$headerMobileHeight);

      .popup-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }
}