@import '../../../assets/variables.scss';

section.dashboard {
  .btn.hidden {
    display: none;
  }

  &.confirm {
    .popup-container {
      padding: .24rem .36rem;
      text-align: left;

      .btn {
        width: 100%;

        &.back:before {
          display: none;
        }
      }

      * + .btn {
        margin-top: .1rem;
      }
    }
  }
}