@import '../../assets/variables.scss';

form.general {
  .form-item {
    position: relative;

    label {
      position: absolute;
      left: .16rem;
      top: .15rem;
      pointer-events: none;
      transition: .2s all ease;

      @include m {
        font-weight: 500;
        font-size: .14rem;
      }
    }

    &.active:not(.file):not(.radio) label,
    input:not([type="checkbox"]):not([type="radio"]):focus + label,
    .select-container.active + label {
      top: .05rem;
      font-size: 0.12rem;

      @include m {
        left: .12rem;
        color: #B4B4B4;
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    textarea {
      width: 100%;
      height: .52rem;
      padding-top: .18rem;
      padding-left: .16rem;
      padding-right: .16rem;
      font-family: "Poppins", sans-serif;
      border: 1px solid #D2D2D2;
      border-radius: .06rem;
      background: #FFFFFF;
      transition: .2s border-color ease;

      @include m {
        padding-left: .12rem;
        padding-right: .12rem;
      }

      &:focus {
        border-color: #4C517A;
      }

      &[disabled] {
        color: $graychat;

        & + label {
          color: $graychat;
        }
      }
    }

    textarea {
      height: 2.48rem;
      resize: none;
    }

    .split-container {
      position: relative;

      @include d {
        margin-left: -.07rem;
        margin-right: -.07rem;
      }

      @include m {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      input[type="text"] {
        display: inline-block;
        vertical-align: middle;
        width: calc((100% / 6) - .14rem);
        height: .62rem;
        padding-top: 0;
        font-weight: 500;
        font-size: .24rem;
        text-align: center;

        @include d {
          margin-left: .07rem;
          margin-right: .07rem;
        }

        @include m {
          width: .48rem;
          height: .48rem;
          font-size: .18rem;
        }
      }

      label {
        position: static;
        display: block;
        margin-bottom: .16rem;

        @include d {
          margin-left: .07rem;
          margin-right: .07rem;
        }

        @include m {
          width: 100%;
        }
      }
    }

    .select-container {
      font-weight: 600;
      font-size: 0.14rem;
      line-height: 1.5em;
      color: $darkblue;

      .select__ {
        &control {
          height: .52rem;
          border: 1px solid #D2D2D2;
          border-radius: .06rem;
          background: #FFFFFF;

          &--is-focused {
            border-color: #4C517A;
            box-shadow: none;
          }
        }

        &indicators {
          height: 100%;
        }

        &indicator-separator {
          display: none;
        }

        &value-container {
          padding: 0 .16rem;

          @include m {
            padding: 0 .12rem;
          }

          & > * {
            margin: 0;
            padding: 0;
          }
        }

        &single-value {
          padding-top: .18rem;
        }

        &placeholder {
          display: none;
        }

        &input input {
          padding-top: .18rem !important;
          font-family: "Poppins", sans-serif;
          font-weight: 600;
          font-size: 0.14rem;
          line-height: 1.5em;
          color: $darkblue;
          box-sizing: border-box !important;
        }
      }

      &.select--is-disabled {
        & + label {
          color: $graychat;
        }
      }
    }

    /* Checkbox */

    input[type='checkbox'] {
      position: absolute;
      left: -9999px;

      ~ label {
        position: relative;
        display: inline-block;
        padding-left: .3rem;
        cursor: pointer;
        user-select: none;
        pointer-events: initial;
        left: auto;
        top: auto;

        &:before {
          content: '';
          position: absolute;
          width: .18rem;
          height: .18rem;
          left: 0;
          top: .01rem;
          border-radius: 2px;
          border: 2px solid $graychat;
          background-color: white;
          box-sizing: border-box;
        }

        a {
          color: inherit;
          text-decoration: underline;
        }
      }

      &:checked {
        ~ label {
          &:before {
            border: none;
            @include bg-image("../assets/Icons/tick.svg");
          }
        }
      }

      &[disabled] {
        ~ label {
          cursor: default;
        }
      }
    }


    /* Radio */

    &.radio {
      display: flex;
      background-color: #FFFFFF;
      border: 1px solid #D2D2D2;
      border-radius: .06rem;

      @include m {
        display: block;
        background-color: transparent;
        border: none;
        border-radius: 0;
      }

      .radio-item {
        margin: .16rem;

        @include m {
          margin: 0;
          padding: .16rem .12rem;
          background-color: #FFFFFF;
          border: 1px solid #D2D2D2;
          border-radius: .06rem;

          & + .radio-item {
            margin-top: .24rem;
          }
        }
      }

      input[type='radio'] {
        position: absolute;
        left: -9999px;

        ~ label {
          position: relative;
          display: inline-block;
          padding-left: .28rem;
          cursor: pointer;
          user-select: none;
          pointer-events: initial;
          left: auto !important;
          top: auto !important;

          @include m {
            display: block;
            padding-left: 0;
            padding-right: .32rem;
          }

          &:before {
            content: '';
            position: absolute;
            width: .18rem;
            height: .18rem;
            left: 0;
            top: 0;
            border-radius: .18rem;
            border: 2px solid $graychat;
            background-color: white;
            box-sizing: border-box;

            @include m {
              left: auto;
              right: 0;
            }
          }

          a {
            color: inherit;
            text-decoration: underline;
          }
        }

        &:checked {
          ~ label {
            &:after {
              content: '';
              position: absolute;
              width: .12rem;
              height: .12rem;
              left: .03rem;
              top: .03rem;
              border-radius: .12rem;
              background-color: $green;

              @include m {
                left: auto;
                right: .03rem;
              }
            }
          }
        }

        &[disabled] {
          ~ label {
            cursor: default;

            &:after {
              background-color: $graychat;
            }
          }
        }
      }

      &.has-error {
        border-color: red;
      }
    }

    /* File */

    &.file {
      padding: .12rem .6rem;
      background: #FFFFFF;
      border: 1px dashed #D2D2D2;
      border-radius: .06rem;

      @include m {
        padding: .08rem .15rem;
        border-radius: .1rem;
      }

      label {
        position: static;
        display: block;
        text-align: center;
      }

      input[type="file"] {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
        overflow: hidden;
      }

      .image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 1.3rem;

        @include m {
          height: .94rem;
        }

        img {
          max-width: 100%;

          @include m {
            width: .56rem;
          }
        }
      }

      .file-buttons {
        display: flex;
        justify-content: center;

        .btn {
          margin: .06rem;
          padding-left: .24rem;
          padding-right: .24rem;

          @include m {
            width: 100%;
            margin: 0;
            padding-left: .12rem;
            padding-right: .12rem;
          }

          &.btn-remove {
            padding-left: .16rem;
            padding-right: .16rem;
          }
        }
      }

      &.has-error {
        border-color: $red;

        label {
          color: $red;
        }
      }
    }


    /* DatePicker */

    .react-datepicker {
      font-family: 'Poppins', sans-serif;
      font-size: .1rem;
      padding: .24rem;
      border-radius: .08rem;
      border: none;
      box-shadow: 0px .02rem .1rem rgba(0, 0, 0, 0.16);
      background-color: white;

      &-wrapper input {
        padding-top: 0;
        padding-left: .42rem;
        background-image: url(../../assets/Icons/calendar.svg);
        background-size: .24rem;
        background-position: .1rem center;
        background-repeat: no-repeat;
      }

      &__header {
        padding: 0;
        background-color: transparent;
        border-top-left-radius: .08rem;
        border-top-right-radius: .08rem;
        border-color: $concretegray;
      }

      &__month {
        margin: 0;
        margin-top: .14rem;
      }

      &__day-names {
        margin-bottom: 0;
        margin-top: .16rem;
        padding-bottom: .07rem;
      }

      &__day-name {
        width: .32rem;
        height: .2rem;
        margin: .05rem .04rem;
        font-weight: 500;
        font-size: .12rem;
        line-height: .2rem;
        color: $graychat;
      }

      &__day {
        width: .32rem;
        height: .32rem;
        margin: .05rem .04rem;
        font-weight: 500;
        font-size: .12rem;
        line-height: .32rem;
        color: $darkgray;
        border-radius: .08rem;

        &--disabled {
          color: $graychat;
        }

        &--in-range,
        &--in-selecting-range {
          background-color: $concretegray;
        }

        &--range-start,
        &--selecting-range-start,
        &--range-end,
        &--selecting-range-end,
        &--keyboard-selected {
          color: white;
          background-color: $darkblue;
        }

        &--range-end {
          color: white;
          background-color: $darkblue;
        }
      }

      &__current-month {
        font-weight: 700;
        font-size: .18rem;
      }

      &__navigation {
        width: .24rem;
        height: .24rem;
        top: .24rem;
      }

      &__navigation--previous {
        left: .32rem;
      }

      &__navigation--next {
        right: .32rem;
      }

      &__triangle {
        display: none;
      }

      &__navigation-icon {
        &::before {
          width: .05rem;
          height: .05rem;
          top: .09rem;
          border-width: 2px 2px 0 0;
        }

        &--previous:before {
          right: -.04rem
        }

        &--next:before {
          left: -.04rem
        }
      }
    }

    .letter-count {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      margin-top: .04rem !important;
      text-align: right;
    }

    .error-message + .letter-count {
      width: 1rem;
    }

    &.has-error {
      input[type="text"],
      input[type="email"],
      input[type="password"],
      textarea,
      input[type='checkbox'] ~ label:before,
      .select-container .select__control {
        border-color: red !important;
      }

      .error-message {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 1rem);
        margin-top: .02rem;
        font-weight: 400;
        font-size: 0.1rem;
        line-height: 1.2em;
        color: #d51317;
      }
    }

    .validations {
      .validation {
        margin-top: .08rem;

        * {
          display: inline-block;
          vertical-align: middle;
        }

        img {
          width: .12rem;
          margin-right: .04rem;
        }

        .text {
          width: calc(100% - .16rem);
          margin-top: 0;
        }
      }
    }
  }
}