/* Colors */

$green: #18C175;
$yellow: #F1BD04;
$red: #FA0050;
$darkblue: #293057;
$concretegray: #F3F3F3;
$graychat: #B4B4B4;
$lightgray: #D2D2D2;
$darkgray: #333333;
$athensgray: #EBEEF2;
$downriver: #9498b8;
$downriverdark: #4C517A;
$downriverlight: #DFE1EA;

/* Static */

$headerMobileHeight: .59rem;


/* Functions */

@mixin m {
  @media screen and (max-width: 900px) {
    @content;
  }
}

@mixin d {
  @media screen and (min-width: 901px) {
    @content;
  }
}

@mixin bg-image($url, $size: cover) {
  background-image: url($url);
  background-size: $size;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin full-height($offset: 0px) {
  height: calc(100vh + #{$offset});
  height: calc(var(--vh, 1vh) * 100 + #{$offset});
}

@mixin min-full-height($offset: 0px) {
  min-height: calc(100vh + #{$offset});
  min-height: calc(var(--vh, 1vh) * 100 + #{$offset});
}