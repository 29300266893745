@import '../../../assets/variables.scss';

footer {
  position: relative;
  padding-top: .14rem;
  padding-bottom: .14rem;
  box-shadow: 0 -.02rem .08rem rgba(0, 0, 0, 0.08);

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @include m {
      flex-direction: column;
      align-items: center;
    }

    > .text {
      @include d {
        width: 100%;
        text-align: center;
      }

      @include m {
        width: calc(100% - .75rem);
        font-size: .12rem;
        text-align: center;
      }
    }
  }

  .yemekpaylogo {
    width: 25%;
  }

  .logo-pcidss, .logo-ssl, .logo-bkm, .logo-tcmb {

    @include m {
      width: .75rem;
      margin: .2rem 0;
    }
  }

  .menu {
    @include m {
      width: 100%;
      order: 2;
      margin-top: .2rem;
      text-align: center;
    }

    a {
      text-decoration: underline;
      display: inline-block;

      @include m {
        display: block;
        margin-left: 0;
      }
    }
  }
  .logo-div-1 {
    width: 5%;
  }
  .logo-div-2 {
    width: 8%;
  }

  .logo-div-3 {
    width: 12%;
  }

  .logo-div-4 {
    width: 2%;
  }

  @media (max-width: 600px) {
    .container {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .menu {
      margin-top: 0;
    }

    .yemekpaylogo {
      width: 100%;
      margin-bottom: .2rem;
    }

    .logo-pcidss, .logo-ssl, .logo-bkm, .logo-tcmb {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }

    .logo-div-1, .logo-div-2, .logo-div-3, .logo-div-4 {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 10px
    }
  }

}
